<template>

  <div>

    <b-card title="Editar pedido">

      <b-row>
        <b-col cols="12">

          <b-tabs
            pills
          >

            <!-- Tab: Account -->
            <b-tab active>
              <template #title>
                <feather-icon
                  icon="EditIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Editar pedido</span>
              </template>
              <order-edit-card 
                :order-id="orderId"
                class="mt-2 pt-75"
              />
            </b-tab>
            
          </b-tabs>

          
        </b-col>
      </b-row>

    </b-card>

  </div>

</template>

<script>
import router from '@/router'
import { BRow, BCol, BTabs, BTab, BCard } from 'bootstrap-vue'
import OrderEditCard from './OrderEditCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,

    OrderEditCard,
  },
  data() {
    return {
      orderId: null,
    }
  },
  created() {
    this.orderId = router.currentRoute.params.order_id
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>