<template>
  <div>

    <!-- User Info: Input Fields -->
    <validation-observer ref="formUploadProducts">

      <b-form
        @submit.prevent="sendAditionalInfoOrder">
        <b-row>

          <b-col
            cols="12"
            md="12"
          >
            <h3 class="mb-2">Conductor / Transpotador</h3>
            <p>Puedes seleccionar el conductor o crearlo para poder asignarlo al pedido</p>
          </b-col>

          <b-col
            cols="12"
            md="12"
          >
            
            <b-form-group
              label="Selecciona conductor"
              label-for="deliverer_id"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Selecciona conductor"
                >

                <b-form-select
                  id="deliverer_id"
                  v-model="delivererId"
                  :options="deliverers"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              
            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="12"
            class="mt-2 mb-2"
          >
            <h4 class="text-primary">Crear conductor</h4>
            <b-card-text>
              Puedes ingresar la información para el conductor si no lo vez en el listado de arriba y asi crearlo en la plataforma
            </b-card-text>
          </b-col>


          <!-- Field: Username -->
          <b-col
            cols="12"
            md="4"
          >

            <b-form-group
              label="Nombre"
              label-for="name"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Nombre"
                  :rules="delivererId != null ? '' : 'required'"
                >

                <b-form-input
                  type="text"
                  id="name"
                  v-model="name"
                  :disabled="delivererId != null"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="4"
          >

            <b-form-group
              label="Placa"
              label-for="plate"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Placa"
                  :rules="delivererId != null ? '' : 'required'"
                >

                <b-form-input
                  type="text"
                  id="plate"
                  v-model="plate"
                  :disabled="delivererId != null"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="4"
          >

            <b-form-group
              label="Cédula"
              label-for="document_number"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Cédula"
                  :rules="delivererId != null ? '' : 'required'"
                >

                <b-form-input
                  type="text"
                  id="document_number"
                  v-model="documentNumber"
                  :disabled="delivererId != null"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="12"
          >
            <h3 class="mb-2 mt-2">Observaciones / Kits / Fundas</h3>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Información adicional"
              label-for="additional_info"
            >
              <b-form-input
                type="text"
                id="additional_info"
                v-model="additionalInfo"
              />

            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Requerimientos especiales"
              label-for="special_requirements"
            >
              <b-form-input
                type="text"
                id="special_requirements"
                v-model="specialRequirements"
              />

            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="¿Esté pedido lleva kits?"
              label-for="kits"
              description="Recuerda activar este item cuando el pedido lleva kits"
            >
            <b-form-checkbox
              id="kits"
              v-model="kits"
            >
              Agregar kits
            </b-form-checkbox>

            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="¿Esté pedido lleva fundas?"
              label-for="cases"
              description="Recuerda activar este item cuando el pedido lleva fundas"
            >
            <b-form-checkbox
              id="cases"
              v-model="cases"
            >
              Agregar fundas
            </b-form-checkbox>

            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="12"
          >
            <h3 class="mb-2 mt-2">Destino</h3>
          </b-col>


          <b-col
            cols="12"
            md="4"
          >
            
            <b-form-group
              label="Destino"
              label-for="product_type_id"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Destino"
                  rules="required"
                >

                <b-form-select
                  id="product_type_id"
                  v-model="destinationId"
                  :options="destinations"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              
            </b-form-group>

          </b-col>

          
        </b-row>

        <!-- Action Buttons -->
        <b-spinner
          v-show="loading"
          variant="primary"
          label="Loading..."
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
           />

        <b-button
          v-show="!loading"
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-2"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          Guardar
        </b-button>
      
      </b-form>
    </validation-observer>

    
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormFile, BFormSelect, BSpinner, BFormText, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {constants} from "@/helpers/constants"

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
    BFormSelect,
    BSpinner,
    BFormText,
    BCardText,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      name: '',
      plate: '',
      documentNumber: '',
      additionalInfo: '',
      specialRequirements: '',
      loading: false,
      required,
      productTypeOptions: [
        {
          text: 'Selecciona una opción',
          value: null,
        },
        {
          text: 'BOLD NEO',
          value: '1',
        },
        {
          text: 'BOLD PLUS',
          value: '2',
        },
        {
          text: 'BOLD SMART',
          value: '3',
        },
        {
          text: 'BOLD PRO',
          value: '4',
        },
      ],
      delivererId: null,
      deliverers: [
        {
          text: 'Selecciona una opción',
          value: null,
        }
      ],
      kits: false,
      cases: false,
      order: null,
      destinations: [
        {
          text: 'Selecciona una opción',
          value: null,
          disabled: true,
        }
      ],
      destinationId: null,
    }
  },
  setup(props) {
    const countryOptions = [
      { label: 'Colombia', value: 1 },
    ]

    const serializedOptions = [
      { label: 'Texto', value: 0 },
      { label: 'JSON', value: 1 },
    ]

    return {
      countryOptions,
      serializedOptions,
    }
  },
  created() {
    this.getDeliverers()
    this.getDestinations()
    this.getOrder()
  },
  methods: {
    getDeliverers(){
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/deliverers', { headers }).then(response => {
        console.log('deliverers: ', response)

        let deliverers = response.data.data.deliverers

        deliverers.forEach(deliverer => {
          this.deliverers.push({
            text: deliverer.name + ' - ' + deliverer.plate,
            value: deliverer.id,
          },)
        })
       
      }).catch( err => {
        console.log(err)
      })

    },

    getDestinations() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/destinations', { headers }).then(response => {
        console.log('destinations: ', response)

        let destinations = response.data.data.destinations

        destinations.forEach(destination => {
          this.destinations.push({
            text: destination.name + ' (' + destination.address + ')',
            value: destination.id,
          },)
        })
       
      }).catch( err => {
        console.log(err)
      })
    },

    getOrder() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/get-order-detail/' + this.orderId, { headers }).then(response => {
        console.log('order: ', response)

        this.order = response.data

        if(this.order.deliverer)
          this.delivererId = this.order.deliverer.id
        this.additionalInfo = this.order.additional_info
        this.specialRequirements = this.order.special_requirements
        this.kits = this.order.kits == 1 ? true : false
        this.cases = this.order.cases == 1 ? true : false
        this.destinationId = this.order.destination_id
        
      }).catch( err => {
        console.log(err)
      })
    },

    sendAditionalInfoOrder() {
      console.log(this.kits)
      event.preventDefault()
      this.$refs.formUploadProducts.validate().then(success => {
        if (success) {

          this.loading = true

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }

          axios.post(this.host + '/api/front-web/update-aditional-info-order', {
            'order_id': this.orderId,
            'name': this.name,
            'plate': this.plate,
            'document_number': this.documentNumber,
            'additional_info': this.additionalInfo,
            'special_requirements': this.specialRequirements,
            'deliverer_id': this.delivererId,
            'kits': this.kits,
            'cases': this.cases,
            'destination_id': this.destinationId,
          }, { headers }).then(response => {
            console.log('update-aditional-info-order: ', response)

            this.loading = false

            if(response.data.status) {

              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `OK`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })

              this.$router.push({ name: 'order-detail', params: { order_id: this.orderId }})

            } else {
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `Advertencia`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
            }
          }).catch( err => {
            console.log(err)
          })

        }
      })


    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
